/* eslint-disable react/no-unknown-property */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ImageUrl } from "../../../utils/constants";
import { GetCompletedInitiatives } from "../../../redux/constants/apiConstants";
// import Waiting from "../../Waiting";
import { toast } from "react-toastify";
import { fetchApi } from "../../../utils/methods";
import dayjs from "dayjs";
import SkeletonLoder from "../../common/skeletonLoder";
import { NoData } from "../styles";
import { HoverBackground } from "./styles";
import { ViewProfileIcon } from "../../../utils/icons";
import DetailPopup from "./detailPopup";

class completedInitiative extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.companyInfo.id,
      launchDate: new Date(),
      completedInitiatives: [],
      year: new Date().getFullYear(),
      pointsArrow: false,
      loading: false,
      onHover: -1,
      showDetailPopup:false,
      detailInitiative:null
    };
  }

  componentDidMount() {
    // const currentYear = new Date().getFullYear();

    this.getCompletedInitiatives(this.props.selectedYearInfo);
  }
  onHoverMouse = (id) => {
    this.setState({
      onHover: id,
    });
  };

  onLeaveHoverMouse = () => {
    this.setState({
      onHover: -1,
    });
  };

  closeDetailPopup=()=>{
    this.setState({showDetailPopup:false,detailInitiative:null})
    
  }

  getCompletedInitiatives = async (year) => {
    this.setState({ loading: true });
    let apiUrl =
      GetCompletedInitiatives + "/" + this.state.companyId + "/" + year;
    try {
      const res = await fetchApi(apiUrl, "GET");
      window.console.log("res is ", res);
      if (res.status === "success") {
        this.setState({ completedInitiatives: res?.data, loading: false });
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  render() {
    const { completedInitiatives, loading, onHover,showDetailPopup,detailInitiative } = this.state;

    // if (completedInitiatives === null) {
    //   <Waiting />;
    // }

    if (loading) {
      return (
        <div style={{ width: "385px" }}>
          <SkeletonLoder width={"1248px"} height={"500px"} />
        </div>
      );
    }

    return completedInitiatives?.length > 0 ? (
      <div style={{ marginTop: "20px" }}>
        <div
          style={{
            width: "1248px",
            background: "white",
            height: "auto",
            margin: "auto",
            padding: "12px",
            display: "flex",
            flexWrap: "wrap",
            gap: "12px",
            borderRadius: "0 0 6px 6px",
          }}
        >
          {completedInitiatives?.length > 0 &&
            completedInitiatives.map((item, index) => (
              <div
                key={index}
                style={{
                  width: "400px",
                  height: "84px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "12px",
                  borderRadius: "6px",
                  border: "1px solid rgba(0, 122, 177, 0.3)",
                  position: "relative",
                  cursor: "pointer",
                }}
                onMouseEnter={() => this.onHoverMouse(item?.id)}
                onMouseLeave={() => this.onLeaveHoverMouse()}
              >
                {item && onHover === item?.id && (
                  <HoverBackground>
                    <div
                      className="view-button"
                      onClick={() => this.setState({showDetailPopup:true,detailInitiative:item})}
                      onMouseEnter={(e) => {
                        e.target.style.background = "#005C87";
                        e.target.style.color = "#ffff";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.background = "#007AB1";
                        e.target.style.color = "white";
                      }}
                    >
                      <div style={{ marginRight: "10px" }}>
                        <ViewProfileIcon />
                      </div>
                      {"View"}
                    </div>
                  </HoverBackground>
                )}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <img
                    src={ImageUrl + "/" + item.initiative_image}
                    alt=""
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "6px",
                    }}
                  />
                  <div>
                    <p
                      style={{
                        color: "#005c87",
                        fontFamily: "rubik-medium",
                        fontSize: "16px",
                        textWrap: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        lineHeight: "20px",
                      }}
                    >
                      {item.initiative_name}
                    </p>
                    <p
                      style={{
                        color: "rgba(0, 92, 135, 0.60)",
                        fontFamily: "rubik",
                        fontSize: "16px",
                        marginBottom: 0,
                        textWrap: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        lineHeight: "20px",
                      }}
                    >
                      Completed on{" "}
                      {dayjs(item.end_date).format("MMMM DD, YYYY")}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {showDetailPopup && <DetailPopup showModal={showDetailPopup} detailInitiative={detailInitiative} onClose={this.closeDetailPopup} companyId={this.props.companyInfo.id}/>}
      </div>
      
    ) : (
      <NoData>No Data Found!</NoData>
    );
  }
}

completedInitiative.propTypes = {
  history: PropTypes.object,
  companyId: PropTypes.any,
  companyInfo: PropTypes.any,
  isHealthDash: PropTypes.bool,
  isLibrary: PropTypes.bool,
  setPage: PropTypes.func,
  role: PropTypes.string,
  selectedYearInfo: PropTypes.number,
};

export default connect(null, null)(completedInitiative);
